import { render, staticRenderFns } from "./EditExternalUsersMatrixSidebar.vue?vue&type=template&id=23c337c3&scoped=true&"
import script from "./EditExternalUsersMatrixSidebar.vue?vue&type=script&lang=ts&"
export * from "./EditExternalUsersMatrixSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./EditExternalUsersMatrixSidebar.vue?vue&type=style&index=0&id=23c337c3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c337c3",
  null
  
)

export default component.exports